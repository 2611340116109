import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    svg: {
      width: 'auto',
      height: 40,
      marginTop: '10px',
    }
  });

const VaultLogo = () => {
  const classes = useStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classes.svg}
      viewBox="0 0 16 16"
      fill="none"
    >
    <path fill="#000000" d="M0 0l7.971 15.516L16 0H0zm6.732 6.16h-1.27V4.89h1.27v1.27zm0-1.906h-1.27V2.985h1.27v1.269zm1.904 3.81h-1.27v-1.27h1.27v1.27zm0-1.905h-1.27V4.89h1.27v1.27zm0-1.905h-1.27V2.985h1.27v1.269zm1.894 1.905H9.26V4.89h1.27v1.27zM9.26 4.254V2.985h1.27v1.269H9.26z"/>
    </svg>
  );
};

export default VaultLogo;