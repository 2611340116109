import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  ApiRef,
  BackstageIdentityApi,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi,
  configApiRef,
  createApiFactory,
  createApiRef,
  discoveryApiRef,
  oauthRequestApiRef,
} from '@backstage/core-plugin-api';
import { TechRadarApiClient } from './lib/TechRadarApiClient';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { OAuth2 } from '@backstage/core-app-api';

export const localOidcAuthApiRef: ApiRef<
    OpenIdConnectApi &
    ProfileInfoApi &
    BackstageIdentityApi &
    SessionApi
> = createApiRef({
  id: 'internal.auth.my-oidc-provider',
});

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory(techRadarApiRef, new TechRadarApiClient()),
  createApiFactory({
    api: localOidcAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
  	  oauthRequestApi: oauthRequestApiRef,
  	  configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      OAuth2.create({
        discoveryApi,
    	  oauthRequestApi,
        provider: {
          id: 'my-oidc-provider',
          title: 'My custom auth provider',
          icon: () => null,
        },
        environment: configApi.getOptionalString('auth.environment'),
        defaultScopes: ['openid', 'email'],
      })
  })
];


