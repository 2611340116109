import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Content, Page } from '@backstage/core-components';

import { HomePageToolkit, HomePageCompanyLogo  } from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import {
  SearchContextProvider,
} from '@backstage/plugin-search-react';

//Icons and Logos
import GlFullLogo from '../../assets/GlFullLogo';
import DocusarusLogo from '../../assets/DocusarusLogo';
import BitBucketLogo from '../../assets/BitBucketLogo';
import JiraLogo from '../../assets/JiraLogo';
import ArgoLogo from '../../assets/ArgoLogosvg';
import HarborLogo from '../../assets/HarborLogo';
import VaultLogo from '../../assets/VaultLogo';
import AWSLogo from '../../assets/AWSLogo';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '40vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    padding: '8px 0',
    margin: 'auto',
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
  }));


export const HomePage = () => {
  const classes = useStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo
              logo={<GlFullLogo/>}
            />
            <Grid container item xs={12} justifyContent='center'>
              <HomePageSearchBar
                classes={{ root: classes.searchBar }}
                InputProps={{ classes: { notchedOutline: classes.searchBarOutline } }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12} justifyContent='center'>
                <HomePageToolkit
                  tools={[
                    {
                      url: 'https://foresttechnologies.atlassian.net/jira/software/projects/GLIDP/boards/513',
                      label: 'JIRA',
                      icon: <JiraLogo />,
                    },
                    {
                      url: 'https://foresttechnologies.atlassian.net/issues/?filter=10733',
                      label: 'Bugs',
                      icon: <JiraLogo />,
                    },
                    {
                      url: 'https://bitbucket.org/ecs-group/workspace/projects/DEID',
                      label: 'BitBucket',
                      icon: <BitBucketLogo />,
                    },
                    {
                      url: 'https://registry.gluki.io/',
                      label: 'Container Registry',
                      icon: <HarborLogo />,
                    },
                    {
                      url: 'https://secrets.gluki.io/',
                      label: 'Secrets',
                      icon: <VaultLogo />,
                    },
                    {
                      url: 'https://ci.gluki.io/',
                      label: 'CI',
                      icon: <ArgoLogo />,
                    },
                    {
                      url: 'https://cd.gluki.io',
                      label: 'CD',
                      icon: <ArgoLogo />,
                    },
                    {
                      url: 'https://docs.gluki.io/',
                      label: 'Docs',
                      icon: <DocusarusLogo />,
                    },
                    {
                      url: 'https://identity.gluki.io/realms/gluki/protocol/saml/clients/amazon-aws',
                      label: 'AWS',
                      icon: <AWSLogo />,
                    },
                  ]}
                />
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  )
};

