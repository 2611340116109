import {
    TechRadarApi,
    TechRadarLoaderResponse,
  } from '@backstage-community/plugin-tech-radar';
  
  
  export class TechRadarApiClient implements TechRadarApi {
    async load(): Promise<TechRadarLoaderResponse> {
      // if needed id prop can be used to fetch the correct data
  
      const data = require('./techRadarData.json')
  
      // For example, this converts the timeline dates into date objects
      return {
        ...data,
        entries: data.entries.map((entry : any) => ({
          ...entry,
          timeline: entry.timeline.map((timeline : any) => ({
            ...timeline,
            date: new Date(timeline.date),
          })),
        })),
      };
    }
  }